import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/SEO"
import { Link } from "gatsby"

class Success extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Thank You" />

        <Container>
          <div className="heading">
            <h2>
              It's on it's way!<br></br>
              Your message has been sent.
            </h2>
          </div>
          <div className="content">
            <p>I will get back to you as soon as I can.</p>

            <Link to="/" className="btn">
              Back Home
            </Link>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Success
